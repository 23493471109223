import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './ai-chat.reducer';

export const AiChatDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const aiChatEntity = useAppSelector(state => state.aiChat.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="aiChatDetailsHeading">
          <Translate contentKey="cpapilotApp.aiChat.detail.title">AiChat</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.id}</dd>
          <dt>
            <span id="aiModel">
              <Translate contentKey="cpapilotApp.aiChat.aiModel">Ai Model</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.aiModel}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="cpapilotApp.aiChat.title">Title</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.title}</dd>
          <dt>
            <span id="aiSystem">
              <Translate contentKey="cpapilotApp.aiChat.aiSystem">Ai System</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.aiSystem}</dd>
          <dt>
            <span id="userRating">
              <Translate contentKey="cpapilotApp.aiChat.userRating">User Rating</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.userRating}</dd>
          <dt>
            <span id="userRatingReason">
              <Translate contentKey="cpapilotApp.aiChat.userRatingReason">User Rating Reason</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.userRatingReason}</dd>
          <dt>
            <span id="context">
              <Translate contentKey="cpapilotApp.aiChat.context">Context</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.context}</dd>
          <dt>
            <span id="examples">
              <Translate contentKey="cpapilotApp.aiChat.examples">Examples</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.examples}</dd>
          <dt>
            <span id="externalChatId">
              <Translate contentKey="cpapilotApp.aiChat.externalChatId">External Chat Id</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.externalChatId}</dd>
          <dt>
            <span id="favourite">
              <Translate contentKey="cpapilotApp.aiChat.favourite">Favourite</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.favourite ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="cpapilotApp.aiChat.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {aiChatEntity.createdDate ? <TextFormat value={aiChatEntity.createdDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="cpapilotApp.aiChat.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>
            {aiChatEntity.updatedDate ? <TextFormat value={aiChatEntity.updatedDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedDate">
              <Translate contentKey="cpapilotApp.aiChat.deletedDate">Deleted Date</Translate>
            </span>
          </dt>
          <dd>
            {aiChatEntity.deletedDate ? <TextFormat value={aiChatEntity.deletedDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="status">
              <Translate contentKey="cpapilotApp.aiChat.status">Status</Translate>
            </span>
          </dt>
          <dd>{aiChatEntity.status}</dd>
          <dt>
            <Translate contentKey="cpapilotApp.aiChat.user">User</Translate>
          </dt>
          <dd>{aiChatEntity.user ? aiChatEntity.user.id : ''}</dd>
          <dt>
            <Translate contentKey="cpapilotApp.aiChat.api">Api</Translate>
          </dt>
          <dd>{aiChatEntity.api ? aiChatEntity.api.id : ''}</dd>
          <dt>
            <Translate contentKey="cpapilotApp.aiChat.aiAssistant">Ai Assistant</Translate>
          </dt>
          <dd>{aiChatEntity.aiAssistant ? aiChatEntity.aiAssistant.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/ai-chat" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/ai-chat/${aiChatEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AiChatDetail;
