import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './ai-chat.reducer';

export const AiChat = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(
    overridePaginationStateWithQueryParams(getSortState(pageLocation, 5, 'id'), pageLocation.search)
  );

  const aiChatList = useAppSelector(state => state.aiChat.entities);
  const loading = useAppSelector(state => state.aiChat.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="ai-chat-heading" data-cy="AiChatHeading">
        <Translate contentKey="cpapilotApp.aiChat.home.title">Ai Chats</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="cpapilotApp.aiChat.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/ai-chat/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="cpapilotApp.aiChat.home.createLabel">Create new Ai Chat</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {aiChatList && aiChatList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="cpapilotApp.aiChat.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('aiModel')}>
                  <Translate contentKey="cpapilotApp.aiChat.aiModel">Ai Model</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('aiModel')} />
                </th>
                <th className="hand" onClick={sort('title')}>
                  <Translate contentKey="cpapilotApp.aiChat.title">Title</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('title')} />
                </th>
                <th className="hand" onClick={sort('aiSystem')}>
                  <Translate contentKey="cpapilotApp.aiChat.aiSystem">Ai System</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('aiSystem')} />
                </th>
                <th className="hand" onClick={sort('userRating')}>
                  <Translate contentKey="cpapilotApp.aiChat.userRating">User Rating</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('userRating')} />
                </th>
                <th className="hand" onClick={sort('userRatingReason')}>
                  <Translate contentKey="cpapilotApp.aiChat.userRatingReason">User Rating Reason</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('userRatingReason')} />
                </th>
                <th className="hand" onClick={sort('context')}>
                  <Translate contentKey="cpapilotApp.aiChat.context">Context</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('context')} />
                </th>
                <th className="hand" onClick={sort('examples')}>
                  <Translate contentKey="cpapilotApp.aiChat.examples">Examples</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('examples')} />
                </th>
                <th className="hand" onClick={sort('externalChatId')}>
                  <Translate contentKey="cpapilotApp.aiChat.externalChatId">External Chat Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('externalChatId')} />
                </th>
                <th className="hand" onClick={sort('favourite')}>
                  <Translate contentKey="cpapilotApp.aiChat.favourite">Favourite</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('favourite')} />
                </th>
                <th className="hand" onClick={sort('createdDate')}>
                  <Translate contentKey="cpapilotApp.aiChat.createdDate">Created Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createdDate')} />
                </th>
                <th className="hand" onClick={sort('updatedDate')}>
                  <Translate contentKey="cpapilotApp.aiChat.updatedDate">Updated Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('updatedDate')} />
                </th>
                <th className="hand" onClick={sort('deletedDate')}>
                  <Translate contentKey="cpapilotApp.aiChat.deletedDate">Deleted Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('deletedDate')} />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="cpapilotApp.aiChat.status">Status</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('status')} />
                </th>
                <th>
                  <Translate contentKey="cpapilotApp.aiChat.user">User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="cpapilotApp.aiChat.api">Api</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="cpapilotApp.aiChat.aiAssistant">Ai Assistant</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {aiChatList.map((aiChat, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/ai-chat/${aiChat.id}`} color="link" size="sm">
                      {aiChat.id}
                    </Button>
                  </td>
                  <td>{aiChat.aiModel}</td>
                  <td>{aiChat.title}</td>
                  <td>
                    <Translate contentKey={`cpapilotApp.AiSystem.${aiChat.aiSystem}`} />
                  </td>
                  <td>{aiChat.userRating}</td>
                  <td>{aiChat.userRatingReason}</td>
                  <td>{aiChat.context}</td>
                  <td>{aiChat.examples}</td>
                  <td>{aiChat.externalChatId}</td>
                  <td>{aiChat.favourite ? 'true' : 'false'}</td>
                  <td>
                    {aiChat.createdDate ? <TextFormat type="date" value={aiChat.createdDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {aiChat.updatedDate ? <TextFormat type="date" value={aiChat.updatedDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {aiChat.deletedDate ? <TextFormat type="date" value={aiChat.deletedDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    <Translate contentKey={`cpapilotApp.ConversationStatus.${aiChat.status}`} />
                  </td>
                  <td>{aiChat.user ? aiChat.user.id : ''}</td>
                  <td>{aiChat.api ? <Link to={`/api/${aiChat.api.id}`}>{aiChat.api.id}</Link> : ''}</td>
                  <td>{aiChat.aiAssistant ? <Link to={`/ai-assistant/${aiChat.aiAssistant.id}`}>{aiChat.aiAssistant.id}</Link> : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/ai-chat/${aiChat.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`/ai-chat/${aiChat.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() => (window.location.href = `/ai-chat/${aiChat.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="cpapilotApp.aiChat.home.notFound">No Ai Chats found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AiChat;
