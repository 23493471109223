import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './api.reducer';

export const Api = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(
    overridePaginationStateWithQueryParams(getSortState(pageLocation, 5, 'id'), pageLocation.search)
  );

  const apiList = useAppSelector(state => state.api.entities);
  const loading = useAppSelector(state => state.api.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="api-heading" data-cy="ApiHeading">
        <Translate contentKey="cpapilotApp.api.home.title">Apis</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="cpapilotApp.api.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/api/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="cpapilotApp.api.home.createLabel">Create new Api</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {apiList && apiList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="cpapilotApp.api.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="cpapilotApp.api.name">Name</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('name')} />
                </th>
                <th className="hand" onClick={sort('docsPortalUrl')}>
                  <Translate contentKey="cpapilotApp.api.docsPortalUrl">Docs Portal Url</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('docsPortalUrl')} />
                </th>
                <th className="hand" onClick={sort('prodUrl')}>
                  <Translate contentKey="cpapilotApp.api.prodUrl">Prod Url</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('prodUrl')} />
                </th>
                <th className="hand" onClick={sort('sandboxUrl')}>
                  <Translate contentKey="cpapilotApp.api.sandboxUrl">Sandbox Url</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('sandboxUrl')} />
                </th>
                <th className="hand" onClick={sort('lastIndexedTimestamp')}>
                  <Translate contentKey="cpapilotApp.api.lastIndexedTimestamp">Last Indexed Timestamp</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lastIndexedTimestamp')} />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {apiList.map((api, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/api/${api.id}`} color="link" size="sm">
                      {api.id}
                    </Button>
                  </td>
                  <td>{api.name}</td>
                  <td>{api.docsPortalUrl}</td>
                  <td>{api.prodUrl}</td>
                  <td>{api.sandboxUrl}</td>
                  <td>
                    {api.lastIndexedTimestamp ? <TextFormat type="date" value={api.lastIndexedTimestamp} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/api/${api.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`/api/${api.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() => (window.location.href = `/api/${api.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="cpapilotApp.api.home.notFound">No Apis found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Api;
