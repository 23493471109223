import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/shared/reducers/user-management';
import { IApi } from 'app/shared/model/api.model';
import { getEntities as getApis } from 'app/entities/api/api.reducer';
import { IAiAssistant } from 'app/shared/model/ai-assistant.model';
import { getEntities as getAiAssistants } from 'app/entities/ai-assistant/ai-assistant.reducer';
import { IAiChat } from 'app/shared/model/ai-chat.model';
import { AiSystem } from 'app/shared/model/enumerations/ai-system.model';
import { ConversationStatus } from 'app/shared/model/enumerations/conversation-status.model';
import { getEntity, updateEntity, createEntity, reset } from './ai-chat.reducer';

export const AiChatUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const apis = useAppSelector(state => state.api.entities);
  const aiAssistants = useAppSelector(state => state.aiAssistant.entities);
  const aiChatEntity = useAppSelector(state => state.aiChat.entity);
  const loading = useAppSelector(state => state.aiChat.loading);
  const updating = useAppSelector(state => state.aiChat.updating);
  const updateSuccess = useAppSelector(state => state.aiChat.updateSuccess);
  const aiSystemValues = Object.keys(AiSystem);
  const conversationStatusValues = Object.keys(ConversationStatus);

  const handleClose = () => {
    navigate('/ai-chat');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
    dispatch(getApis({}));
    dispatch(getAiAssistants({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.userRating !== undefined && typeof values.userRating !== 'number') {
      values.userRating = Number(values.userRating);
    }

    const entity = {
      ...aiChatEntity,
      ...values,
      user: users.find(it => it.id.toString() === values.user?.toString()),
      api: apis.find(it => it.id.toString() === values.api?.toString()),
      aiAssistant: aiAssistants.find(it => it.id.toString() === values.aiAssistant?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          aiSystem: 'OpenAI',
          status: 'New',
          ...aiChatEntity,
          user: aiChatEntity?.user?.id,
          api: aiChatEntity?.api?.id,
          aiAssistant: aiChatEntity?.aiAssistant?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cpapilotApp.aiChat.home.createOrEditLabel" data-cy="AiChatCreateUpdateHeading">
            <Translate contentKey="cpapilotApp.aiChat.home.createOrEditLabel">Create or edit a AiChat</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="ai-chat-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('cpapilotApp.aiChat.aiModel')}
                id="ai-chat-aiModel"
                name="aiModel"
                data-cy="aiModel"
                type="text"
              />
              <ValidatedField label={translate('cpapilotApp.aiChat.title')} id="ai-chat-title" name="title" data-cy="title" type="text" />
              <ValidatedField
                label={translate('cpapilotApp.aiChat.aiSystem')}
                id="ai-chat-aiSystem"
                name="aiSystem"
                data-cy="aiSystem"
                type="select"
              >
                {aiSystemValues.map(aiSystem => (
                  <option value={aiSystem} key={aiSystem}>
                    {translate('cpapilotApp.AiSystem.' + aiSystem)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('cpapilotApp.aiChat.userRating')}
                id="ai-chat-userRating"
                name="userRating"
                data-cy="userRating"
                type="text"
              />
              <ValidatedField
                label={translate('cpapilotApp.aiChat.userRatingReason')}
                id="ai-chat-userRatingReason"
                name="userRatingReason"
                data-cy="userRatingReason"
                type="text"
              />
              <ValidatedField
                label={translate('cpapilotApp.aiChat.context')}
                id="ai-chat-context"
                name="context"
                data-cy="context"
                type="text"
              />
              <ValidatedField
                label={translate('cpapilotApp.aiChat.examples')}
                id="ai-chat-examples"
                name="examples"
                data-cy="examples"
                type="text"
              />
              <ValidatedField
                label={translate('cpapilotApp.aiChat.externalChatId')}
                id="ai-chat-externalChatId"
                name="externalChatId"
                data-cy="externalChatId"
                type="text"
              />
              <ValidatedField
                label={translate('cpapilotApp.aiChat.favourite')}
                id="ai-chat-favourite"
                name="favourite"
                data-cy="favourite"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('cpapilotApp.aiChat.createdDate')}
                id="ai-chat-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="date"
              />
              <ValidatedField
                label={translate('cpapilotApp.aiChat.updatedDate')}
                id="ai-chat-updatedDate"
                name="updatedDate"
                data-cy="updatedDate"
                type="date"
              />
              <ValidatedField
                label={translate('cpapilotApp.aiChat.deletedDate')}
                id="ai-chat-deletedDate"
                name="deletedDate"
                data-cy="deletedDate"
                type="date"
              />
              <ValidatedField
                label={translate('cpapilotApp.aiChat.status')}
                id="ai-chat-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {conversationStatusValues.map(conversationStatus => (
                  <option value={conversationStatus} key={conversationStatus}>
                    {translate('cpapilotApp.ConversationStatus.' + conversationStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField id="ai-chat-user" name="user" data-cy="user" label={translate('cpapilotApp.aiChat.user')} type="select">
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="ai-chat-api" name="api" data-cy="api" label={translate('cpapilotApp.aiChat.api')} type="select">
                <option value="" key="0" />
                {apis
                  ? apis.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="ai-chat-aiAssistant"
                name="aiAssistant"
                data-cy="aiAssistant"
                label={translate('cpapilotApp.aiChat.aiAssistant')}
                type="select"
              >
                <option value="" key="0" />
                {aiAssistants
                  ? aiAssistants.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/ai-chat" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AiChatUpdate;
