import { IUser } from 'app/shared/model/user.model';
import { ApiManagerRole } from 'app/shared/model/enumerations/api-manager-role.model';

export interface IApiManager {
  id?: number;
  role?: keyof typeof ApiManagerRole | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IApiManager> = {};
