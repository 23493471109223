import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, ListGroup } from 'reactstrap';

export const GeneratedmessageDetailsView = props => {
  const [airesponse, setAiresponse] = useState<string[]>([]);
  const [chatConversation, setChatConversation] = useState<string[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  };

  // Updating state for airesponse and user messages
  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToBottom();
    }, 100);

    if (props.userConversationAI) {
      setAiresponse(props?.userConversationAI);
    }
    
    if (props.userConversation) {
      setChatConversation(props?.userConversation);
    }
    return () => clearTimeout(timer); // Cleanup timer when component is unmounted or before running this effect again
  }, [props.userConversationAI, props.userConversation]);

  return (
    <Row className="content-paragraph" style={props?.showTemplates ? { display: 'none' } : { display: 'block' }}>
      <Col>
        <ListGroup>
          {/* Show User Conversation or AI response */}
          {chatConversation && chatConversation.length > 0 ? (
            <div className="inner-msg">
              {chatConversation.map((message, index) => (
                <div key={index}>{message}</div>
              ))}
            </div>
          ) : (
            airesponse.length > 0 && (
              <div className="inner-msg">
                {airesponse.map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </div>
            )
          )}
        </ListGroup>

        {/* Show loading spinner if loading is true */}
        {props.loading && (
          <p>
            <img className="cpapilot-loading-img" src="content/images/loading.gif" alt="Logo" />
          </p>
        )}
      </Col>

      {/* Empty div that will be scrolled to */}
      <div ref={messagesEndRef}></div>
    </Row>
  );
};

export default GeneratedmessageDetailsView;
