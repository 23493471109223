import dayjs from 'dayjs';

export interface IApi {
  id?: number;
  name?: string | null;
  docsPortalUrl?: string | null;
  prodUrl?: string | null;
  sandboxUrl?: string | null;
  lastIndexedTimestamp?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IApi> = {};
