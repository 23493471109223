import dayjs from 'dayjs';
import { Persona } from 'app/shared/model/enumerations/persona.model';

export interface IUserPrompt {
  id?: number;
  promptText?: string;
  promptPersona?: keyof typeof Persona | null;
  createdDate?: dayjs.Dayjs | null;
  updatedDate?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IUserPrompt> = {};
