import '../../../../../../app/shared/layout/header/header.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Navbar, NavbarToggler } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../../../app/config/store';
import { createEntity } from '../../../entities/ai-chat/ai-chat.reducer';
import { setSidebarToggle } from '../../../entities/sidebar/sidebar.reducer';
// import { Translate, Storage } from 'react-jhipster';
// import { Home, Brand } from '../../../../../../app/shared/layout/header/header-components';
// import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../../../../../../app/shared/layout/menus';
// import { setLocale } from '../../../../../../app/shared/reducers/locale';

import {
  setShowTemplates,
  setUserOldConversationId,
  setIsOpenChatList,
  setIsOpenFavourites,
  setShowExtraPrompts,
  setUserPromptFullText,
  setShowSubscriptionAlert,
  setSelectedPrompt,
  setUserOldConversation,
  setMobileViewNewChatButtonClicked,
} from '../../../entities/chat-dashboard/chat-dashboard.reducer';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const Header = (props: IHeaderProps) => {
  const dispatch = useAppDispatch();
  const sidebarOpen = useAppSelector(state => state.sidebar.sidebarOpen);
  const aiAssistantList = useAppSelector(state => state.aiAssistant.entities);
  const account = useAppSelector(state => state.authentication.account);
  const messageCount = useAppSelector(state => state.aiChat.messageCount);
  // const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownToggle = () => setDropdownOpen(prevState => !prevState);
  const [categoryOpen, setCategoryOpen] = useState({});
  const [botSelection, setBotSelection] = useState<any>({});
  const [userConversation, setUserConversation] = useState<any>();
  // const [disabledNewChatPlusButton, setDisabledNewChatPlusButton] = useState(false);

  // const handleLocaleChange = event => {
  //   const langKey = event.target.value;
  //   Storage.session.set('locale', langKey);
  //   dispatch(setLocale(langKey));
  // };

  // const renderDevRibbon = () =>
  //   props.isInProduction === false ? (
  //     <div className="ribbon dev">
  //       <a href="">
  //         <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
  //       </a>
  //     </div>
  //   ) : null;

  //const toggleMenu = () => setMenuOpen(!menuOpen);

  const toggleMenu = () => {
    // setMenuOpen(!menuOpen);
    dispatch(setSidebarToggle(!sidebarOpen));
  };

  // DropDown Functinality
  const toggleCategory = category => {
    setCategoryOpen(prevState => ({
      ...prevState,
      [category]: prevState[category] == true ? false : true,
    }));
  };
  //  useEffect(() => {
  //     const { planType, trial, subscriptionActive } = account || {};
  //     const isBalanceZero = parseInt(messageCount.balance) === 0;
  //     let disabledNewChatPlusButton = false;
  
  //     if (!planType) {
  //       // Case: No plan
  //       disabledNewChatPlusButton = true;
  //     } else if (planType === 'trial') {
  //       if (!subscriptionActive) {
  //         disabledNewChatPlusButton = true;
  //       }
  //     } else if (planType === 'paid') {
  //       if (!subscriptionActive) {
  //         disabledNewChatPlusButton = true;
  //       }
  //     }
  //     setDisabledNewChatPlusButton(disabledNewChatPlusButton);
  //   }, [account, messageCount]);
  

  // Handle new chat initiation when an AI assistant is selected from the dropdown.
  const handleStartNewChat = async event => {
    let assistant_id = event.target.getAttribute('data-aiAssistantID');
    let openedCategory = Object.keys(categoryOpen).find(key => categoryOpen[key] === true);
    let assistant_obj = aiAssistantList[0]?.[openedCategory]?.filter(aiAssistant => {
      return parseInt(assistant_id) == aiAssistant.id;
    });
    setBotSelection(assistant_obj[0]);
    setDropdownOpen(false);
  };

  // Initialize new chat on selection.
  useEffect(() => {
    if (Object.keys(botSelection).length !== 0) {
      startConversation();
    }
  }, [botSelection]);

  // Initialize new conversation and reset state.
  const startConversation = async () => {
    setUserConversation([]);
    dispatch(setUserPromptFullText(''));
    dispatch(setUserOldConversationId(null));
    const now = new Date();
    const uniqueStr = now.toISOString().replace(/[-:.TZ]/g, '');
    dispatch(setMobileViewNewChatButtonClicked(uniqueStr));
    dispatch(setShowTemplates(true));
    dispatch(setIsOpenFavourites(false));
    dispatch(setIsOpenChatList(true));
    dispatch(setShowExtraPrompts(false));
    await dispatch(
      createEntity({
        aiAssistant: botSelection,
      })
    );
  };

  // const onStartNewConversation = async () => {
  //   dispatch(createEntity({}));
  //   dispatch(setShowTemplates(true));
  //   dispatch(setUserOldConversationId(null));
  //   const now = new Date();
  //   const uniqueStr = now.toISOString().replace(/[-:.TZ]/g, '');
  //   dispatch(setMobileViewNewChatButtonClicked(uniqueStr));
  //   // dispatch(setUserOldConversation(''));
  //   dispatch(setIsOpenFavourites(false));
  //   dispatch(setIsOpenChatList(true));
  // };

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */
  return (
    <div id="app-header">
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" dark expand="md" className="jh-navbar">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        {/* <Brand />
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className="ms-auto" navbar>
            <Home />
            {props.isAuthenticated && <EntitiesMenu />}
            {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
            <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
            <AccountMenu isAuthenticated={props.isAuthenticated} />
          </Nav>
        </Collapse> */}
        <div className="d-flex justify-content-end">
          {/* <Button color="warning" className="float-right" onClick={onStartNewConversation}>
            <FontAwesomeIcon icon={'plus'} />
          </Button> */}
          <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle} direction={'down'}>
            {/* <DropdownToggle color={disabledNewChatPlusButton?"secondary":"warning"} disabled={disabledNewChatPlusButton}> */}
            <DropdownToggle color="warning" >
              <FontAwesomeIcon icon={'plus'} />
            </DropdownToggle>
            <DropdownMenu>
              {Object?.keys(aiAssistantList[0] || {}).map((category, i) => {
                return (
                  <DropdownItem key={i}>
                    <Dropdown
                      isOpen={categoryOpen[category]}
                      direction={'left'}
                      toggle={e => {
                        e.stopPropagation();
                        toggleCategory(category);
                      }}
                    >
                      <DropdownToggle nav={true} caret={true}  style={{ color: 'black' }}>
                        {' '}
                        {category}{' '}
                      </DropdownToggle>
                      <DropdownMenu>
                        {aiAssistantList[0][category].map((aiAssistant, j) => {
                          return (
                            <DropdownItem
                              key={j}
                              data-aiAssistantID={aiAssistant.id}
                              onClick={e => {
                                handleStartNewChat(e);
                              }}
                            >
                              {' '}
                              {aiAssistant.botName}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
