import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './user-subcription.reducer';

export const UserSubcription = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(
    overridePaginationStateWithQueryParams(getSortState(pageLocation, 5, 'id'), pageLocation.search)
  );

  const userSubcriptionList = useAppSelector(state => state.userSubcription.entities);
  const loading = useAppSelector(state => state.userSubcription.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="user-subcription-heading" data-cy="UserSubcriptionHeading">
        <Translate contentKey="cpapilotApp.userSubcription.home.title">User Subcriptions</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="cpapilotApp.userSubcription.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/user-subcription/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="cpapilotApp.userSubcription.home.createLabel">Create new User Subcription</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {userSubcriptionList && userSubcriptionList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="cpapilotApp.userSubcription.id">ID</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('active')}>
                  <Translate contentKey="cpapilotApp.userSubcription.active">Active</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('active')} />
                </th>
                <th className="hand" onClick={sort('orderId')}>
                  <Translate contentKey="cpapilotApp.userSubcription.orderId">Order Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('orderId')} />
                </th>
                <th className="hand" onClick={sort('activeplan')}>
                  <Translate contentKey="cpapilotApp.userSubcription.activeplan">Activeplan</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('activeplan')} />
                </th>
                <th className="hand" onClick={sort('planStartDate')}>
                  <Translate contentKey="cpapilotApp.userSubcription.planStartDate">Plan Start Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('planStartDate')} />
                </th>
                <th className="hand" onClick={sort('planEndDate')}>
                  <Translate contentKey="cpapilotApp.userSubcription.planEndDate">Plan End Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('planEndDate')} />
                </th>
                <th className="hand" onClick={sort('planLimit')}>
                  <Translate contentKey="cpapilotApp.userSubcription.planLimit">Plan Limit</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('planLimit')} />
                </th>
                <th className="hand" onClick={sort('trial')}>
                  <Translate contentKey="cpapilotApp.userSubcription.trial">Trial</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('trial')} />
                </th>
                <th>
                  <Translate contentKey="cpapilotApp.userSubcription.internalUser">Internal User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {userSubcriptionList.map((userSubcription, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/user-subcription/${userSubcription.id}`} color="link" size="sm">
                      {userSubcription.id}
                    </Button>
                  </td>
                  <td>{userSubcription.active ? 'true' : 'false'}</td>
                  <td>{userSubcription.orderId}</td>
                  <td>{userSubcription.activeplan}</td>
                  <td>
                    {userSubcription.planStartDate ? (
                      <TextFormat type="date" value={userSubcription.planStartDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {userSubcription.planEndDate ? (
                      <TextFormat type="date" value={userSubcription.planEndDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{userSubcription.planLimit}</td>
                  <td>{userSubcription.trial ? 'true' : 'false'}</td>
                  <td>{userSubcription.internalUser ? userSubcription.internalUser.login : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/user-subcription/${userSubcription.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/user-subcription/${userSubcription.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() => (window.location.href = `/user-subcription/${userSubcription.id}/delete`)}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="cpapilotApp.userSubcription.home.notFound">No User Subcriptions found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default UserSubcription;
