import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AiChat from './ai-chat';
import GeneratedMessage from './generated-message';
import UserPrompt from './user-prompt';
import Api from './api';
import ApiEndpoint from './api-endpoint';
import ApiManager from './api-manager';
import UserSubcription from './user-subcription';
import SubscriptionLimit from './subscription-limit';
import Citations from './citations';
import AiAssistant from './ai-assistant';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="ai-chat/*" element={<AiChat />} />
        <Route path="generated-message/*" element={<GeneratedMessage />} />
        <Route path="user-prompt/*" element={<UserPrompt />} />
        <Route path="api/*" element={<Api />} />
        <Route path="api-endpoint/*" element={<ApiEndpoint />} />
        <Route path="api-manager/*" element={<ApiManager />} />
        <Route path="user-subcription/*" element={<UserSubcription />} />
        <Route path="subscription-limit/*" element={<SubscriptionLimit />} />
        <Route path="citations/*" element={<Citations />} />
        <Route path="ai-assistant/*" element={<AiAssistant />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
