export interface IAiAssistant {
  id?: number;
  botName?: string | null;
  projectId?: string | null;
  category?: string | null;
  isActive?: boolean | null;
  type?: string | null;
}

export const defaultValue: Readonly<IAiAssistant> = {
  isActive: false,
};
