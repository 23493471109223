import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from '../../../../../app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from '../../../../../app/shared/reducers/reducer.utils';
import { IAiChat, defaultValue } from '../../../../../app/shared/model/ai-chat.model';

const initialState: EntityState<IAiChat> = {
  loading: false,
  generatingMessage: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  messageCount: {
    planLimit: null,
    balance: null,
  },
};

const apiUrl = 'api/ai-chats';
const userChatApiUrl = 'api/user-chat';
const deleteAiChatApiUrl = 'api/aichat/delete';
const searchAiChatApiUrl = 'api/search-conversation';
const messageCountApiUrl = 'api/message-count';

// Actions

export const getEntities = createAsyncThunk('aiChat/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}${
    query ? `&${query}` : ''
  }`;
  return axios.get<IAiChat[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'aiChat/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.get<IAiChat>(requestUrl);
    if (result.status == 200) {
      let chatData = { currentChatId: '', currentPrompt: '' };
      localStorage.setItem('chatData', JSON.stringify(chatData));
    }
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getUpgrade = createAsyncThunk(
  'api/trial-url',
  async () => {
    const requestUrl = `api/trial-url`;
    const result = await axios.get<IAiChat>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'aiChat/create_entity',
  async (entity: IAiChat, thunkAPI) => {
    const result = await axios.post<IAiChat>(apiUrl, cleanEntity(entity));
    //thunkAPI.dispatch(getEntities({}));
    let chatData = { currentChatId: '', currentPrompt: '' };
    localStorage.setItem('chatData', JSON.stringify(chatData));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const sendUserPrompt = createAsyncThunk(
  'api/user-chat',
  async (entity: IAiChat) => {
    const result = await axios.post<IAiChat>(userChatApiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'aiChat/update_entity',
  async (entity: IAiChat, thunkAPI) => {
    const result = await axios.put<IAiChat>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'aiChat/partial_update_entity',
  async (entity: IAiChat, thunkAPI) => {
    const result = await axios.patch<IAiChat>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'aiChat/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IAiChat>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteAiChat = createAsyncThunk(
  'aiChat/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${deleteAiChatApiUrl}/${id}`;
    const result = await axios.delete<IAiChat>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getAiChatBYSearchQuery = createAsyncThunk(
  'aiChat/search_entity',
  async ({ searchTitle, isFavorite }: { searchTitle: string; isFavorite: boolean }) => {
    const requestUrl = `${searchAiChatApiUrl}?searchTitle=${encodeURIComponent(searchTitle)}&isFavorite=${isFavorite}`;
    return axios.get<IAiChat>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getRemainingMessagesLimit = createAsyncThunk(
  'aiChat/message-count',
  async () => {
    const requestUrl = `${messageCountApiUrl}`;
    return await axios.get<IAiChat>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const AiChatSlice = createEntitySlice({
  name: 'aiChat',
  initialState,
  reducers: {
    updateMessageCount: (state, action) => {
      state.messageCount = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getRemainingMessagesLimit.fulfilled, (state, action) => {
        state.messageCount = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities, getAiChatBYSearchQuery), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(sendUserPrompt), (state, action) => {
        state.updateSuccess = true;
        state.generatingMessage = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(sendUserPrompt), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.generatingMessage = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, updateMessageCount } = AiChatSlice.actions;

// Reducer
export default AiChatSlice.reducer;
