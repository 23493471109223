import aiChat from '../entities/ai-chat/ai-chat.reducer';
import generatedMessage from '../../../../app/entities/generated-message/generated-message.reducer';
import userPrompt from '../../../../app/entities/user-prompt/user-prompt.reducer';
import api from '../../../../app/entities/api/api.reducer';
import apiEndpoint from '../../../../app/entities/api-endpoint/api-endpoint.reducer';
import apiManager from '../../../../app/entities/api-manager/api-manager.reducer';
import sidebar from '../entities/sidebar/sidebar.reducer';
import chatDashboard from '../entities/chat-dashboard/chat-dashboard.reducer';
import citations from '../../../../app/entities/citations/citations.reducer';
import aiAssistant from '../../../../app/entities/ai-assistant/ai-assistant.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  aiChat,
  generatedMessage,
  userPrompt,
  api,
  apiEndpoint,
  apiManager,
  sidebar,
  chatDashboard,
  citations,
  aiAssistant,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
