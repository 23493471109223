import { ApiEndpointMethod } from 'app/shared/model/enumerations/api-endpoint-method.model';

export interface IApiEndpoint {
  id?: number;
  method?: keyof typeof ApiEndpointMethod | null;
  uri?: string | null;
  inputEntity?: string | null;
  outputEntity?: string | null;
}

export const defaultValue: Readonly<IApiEndpoint> = {};
