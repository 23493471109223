import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IApi } from 'app/shared/model/api.model';
import { IAiAssistant } from 'app/shared/model/ai-assistant.model';
import { AiSystem } from 'app/shared/model/enumerations/ai-system.model';
import { ConversationStatus } from 'app/shared/model/enumerations/conversation-status.model';

export interface IAiChat {
  id?: number;
  aiModel?: string | null;
  title?: string | null;
  aiSystem?: keyof typeof AiSystem | null;
  userRating?: number | null;
  userRatingReason?: string | null;
  context?: string | null;
  examples?: string | null;
  externalChatId?: string | null;
  favourite?: boolean | null;
  createdDate?: dayjs.Dayjs | null;
  updatedDate?: dayjs.Dayjs | null;
  deletedDate?: dayjs.Dayjs | null;
  status?: keyof typeof ConversationStatus | null;
  user?: IUser | null;
  api?: IApi | null;
  aiAssistant?: IAiAssistant | null;
}

export const defaultValue: Readonly<IAiChat> = {
  favourite: false,
};
