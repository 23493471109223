import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AiAssistant from './ai-assistant';
import AiAssistantDetail from './ai-assistant-detail';
import AiAssistantUpdate from './ai-assistant-update';
import AiAssistantDeleteDialog from './ai-assistant-delete-dialog';

const AiAssistantRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AiAssistant />} />
    <Route path="new" element={<AiAssistantUpdate />} />
    <Route path=":id">
      <Route index element={<AiAssistantDetail />} />
      <Route path="edit" element={<AiAssistantUpdate />} />
      <Route path="delete" element={<AiAssistantDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AiAssistantRoutes;
