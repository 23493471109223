import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUserSubcription } from 'app/shared/model/user-subcription.model';
import { getEntities as getUserSubcriptions } from 'app/entities/user-subcription/user-subcription.reducer';
import { IAiChat } from 'app/shared/model/ai-chat.model';
import { getEntities as getAiChats } from 'app/entities/ai-chat/ai-chat.reducer';
import { IUserPrompt } from 'app/shared/model/user-prompt.model';
import { getEntities as getUserPrompts } from 'app/entities/user-prompt/user-prompt.reducer';
import { IGeneratedMessage } from 'app/shared/model/generated-message.model';
import { Reactions } from 'app/shared/model/enumerations/reactions.model';
import { getEntity, updateEntity, createEntity, reset } from './generated-message.reducer';

export const GeneratedMessageUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const userSubcriptions = useAppSelector(state => state.userSubcription.entities);
  const aiChats = useAppSelector(state => state.aiChat.entities);
  const userPrompts = useAppSelector(state => state.userPrompt.entities);
  const generatedMessageEntity = useAppSelector(state => state.generatedMessage.entity);
  const loading = useAppSelector(state => state.generatedMessage.loading);
  const updating = useAppSelector(state => state.generatedMessage.updating);
  const updateSuccess = useAppSelector(state => state.generatedMessage.updateSuccess);
  const reactionsValues = Object.keys(Reactions);

  const handleClose = () => {
    navigate('/generated-message');
    navigate('/generated-message');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUserSubcriptions({}));
    dispatch(getAiChats({}));
    dispatch(getUserPrompts({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.userRating !== undefined && typeof values.userRating !== 'number') {
      values.userRating = Number(values.userRating);
    }

    const entity = {
      ...generatedMessageEntity,
      ...values,
      userSubcription: userSubcriptions.find(it => it.id.toString() === values.userSubcription?.toString()),
      aiChat: aiChats.find(it => it.id.toString() === values.aiChat?.toString()),
      userPrompt: userPrompts.find(it => it.id.toString() === values.userPrompt?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          userReaction: 'Neutral',
          ...generatedMessageEntity,
          userSubcription: generatedMessageEntity?.userSubcription?.id,
          aiChat: generatedMessageEntity?.aiChat?.id,
          userPrompt: generatedMessageEntity?.userPrompt?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cpapilotApp.generatedMessage.home.createOrEditLabel" data-cy="GeneratedMessageCreateUpdateHeading">
            <Translate contentKey="cpapilotApp.generatedMessage.home.createOrEditLabel">Create or edit a GeneratedMessage</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="generated-message-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('cpapilotApp.generatedMessage.userRating')}
                id="generated-message-userRating"
                name="userRating"
                data-cy="userRating"
                type="text"
              />
              <ValidatedField
                label={translate('cpapilotApp.generatedMessage.userRatingReason')}
                id="generated-message-userRatingReason"
                name="userRatingReason"
                data-cy="userRatingReason"
                type="text"
              />
              <ValidatedField
                label={translate('cpapilotApp.generatedMessage.formattedMessage')}
                id="generated-message-formattedMessage"
                name="formattedMessage"
                data-cy="formattedMessage"
                type="text"
              />
              <ValidatedField
                label={translate('cpapilotApp.generatedMessage.aiModel')}
                id="generated-message-aiModel"
                name="aiModel"
                data-cy="aiModel"
                type="text"
              />
              <ValidatedField
                label={translate('cpapilotApp.generatedMessage.rawMessage')}
                id="generated-message-rawMessage"
                name="rawMessage"
                data-cy="rawMessage"
                type="textarea"
              />
              <ValidatedField
                id="generated-message-userSubcription"
                name="userSubcription"
                data-cy="userSubcription"
                label={translate('cpapilotApp.generatedMessage.userSubcription')}
                type="select"
              >
                <option value="" key="0" />
                {userSubcriptions
                  ? userSubcriptions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('cpapilotApp.generatedMessage.extMessageId')}
                id="generated-message-extMessageId"
                name="extMessageId"
                data-cy="extMessageId"
                type="text"
              />
              <ValidatedField
                label={translate('cpapilotApp.generatedMessage.userReaction')}
                id="generated-message-userReaction"
                name="userReaction"
                data-cy="userReaction"
                type="select"
              >
                {reactionsValues.map(reactions => (
                  <option value={reactions} key={reactions}>
                    {translate('cpapilotApp.Reactions.' + reactions)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                id="generated-message-aiChat"
                name="aiChat"
                data-cy="aiChat"
                label={translate('cpapilotApp.generatedMessage.aiChat')}
                type="select"
              >
                <option value="" key="0" />
                {aiChats
                  ? aiChats.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="generated-message-userPrompt"
                name="userPrompt"
                data-cy="userPrompt"
                label={translate('cpapilotApp.generatedMessage.userPrompt')}
                type="select"
              >
                <option value="" key="0" />
                {userPrompts
                  ? userPrompts.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/generated-message" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default GeneratedMessageUpdate;
